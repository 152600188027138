import React from 'react';
import { useSelector } from 'react-redux';

import { Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import { Icon } from 'semantic-ui-react';

import { ontologyLabelsItemsSelector } from 'selectors/entities';

import { RouterLinkV2 } from 'components/ui/Link';
import { AnalyticsAwareButton } from 'components/ui/button';
import { PrimaryTabButton } from 'components/ui/button/TabButton';
import { StyledDropdown } from 'components/ui/inputs/Dropdown';
import Breadcrumb from 'components/ui/navigation/Breadcrumb';
import { SecondaryNavbarContainer } from 'components/ui/navigation/NavigationTabs';

import capitalizedTranslation from 'utils/i18n';

import * as svars from 'assets/style/variables';

function ExplorationSecondaryNavbar({
  ontologyId,
  selectedConceptItem,
  customTreemapData,
  singleAnalyzedOntology,
  onSelectOntology,
  onSelectConcept,
  getSearchRouteParameters,
}) {
  const ontologiesLabelsItems = useSelector(ontologyLabelsItemsSelector);
  return (
    <SecondaryNavbarContainer
      style={{
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        position: 'sticky',
        top: 0,
        zIndex: 3,
        padding: `0 ${svars.spaceMedium}`,
      }}
    >
      <span style={{ display: 'inline-flex', alignItems: 'center' }}>
        {!singleAnalyzedOntology ? (
          <span
            style={{
              display: 'inline-flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              paddingRight: svars.spaceNormal,
            }}
          >
            <span
              style={{
                paddingRight: svars.spaceNormal,
              }}
            >
              <Trans render={capitalizedTranslation} id="ontology" />
              &nbsp;:
            </span>

            <StyledDropdown
              upward
              selection
              deburr
              options={ontologiesLabelsItems}
              onChange={onSelectOntology}
              value={ontologyId}
              style={{ width: 'auto' }}
            />
          </span>
        ) : null}
        <Trans id="category" render={capitalizedTranslation} />
        &nbsp;:
        <Breadcrumb
          fontSize={svars.fontSizeLarge}
          path={Breadcrumb.getHierarchichalPath(
            selectedConceptItem?.id || null,
            customTreemapData
          )}
          onClick={onSelectConcept}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            flexWrap: 'wrap',
          }}
        />
      </span>
      <RouterLinkV2
        base="true"
        to={getSearchRouteParameters([], null)}
        style={{
          padding: svars.spaceSmall,
          marginLeft: svars.spaceMediumLarge,
          fontSize: svars.fontSizeLarge,
        }}
      >
        <AnalyticsAwareButton
          inputComponent={PrimaryTabButton}
          gaCategory="Dashboard - Explore"
          gaAction="Navigate to concept search"
          gaLabel={selectedConceptItem?.id || 'global'}
          style={{
            whiteSpace: 'nowrap',
          }}
        >
          <Icon name="search" />
          <Trans
            id="dashboard-explore.see-extracts"
            render={capitalizedTranslation}
          />
        </AnalyticsAwareButton>
      </RouterLinkV2>
    </SecondaryNavbarContainer>
  );
}

ExplorationSecondaryNavbar.propTypes = {
  ontologyId: PropTypes.string,
  selectedConceptItem: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }),
  customTreemapData: PropTypes.arrayOf(PropTypes.shape({})),
  singleAnalyzedOntology: PropTypes.bool.isRequired,
  onSelectOntology: PropTypes.func.isRequired,
  onSelectConcept: PropTypes.func.isRequired,
  getSearchRouteParameters: PropTypes.func.isRequired,
};

ExplorationSecondaryNavbar.defaultProps = {
  ontologyId: null,
  selectedConceptItem: null,
  customTreemapData: null,
};

export default ExplorationSecondaryNavbar;
