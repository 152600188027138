import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';

import { Trans, t } from '@lingui/macro';
import sortBy from 'lodash/sortBy';
import PropTypes from 'prop-types';
import { Dropdown, Icon } from 'semantic-ui-react';
import styled from 'styled-components';

import {
  ontologyLabelsSelector as entityOntologyLabelsSelector,
  ontologiesItemsSelector,
} from 'selectors/entities';

import SwitchGroup from 'components/ui/button/SwitchGroup';

import { capitalize } from 'utils/helpers';

import * as svars from 'assets/style/variables';

import CheckboxListFilter from './CheckboxListFilter';

const CONCEPT_MATCH_SWITCH_ITEMS = [
  { key: 'and', i18nLabel: t`AND`, value: true },
  { key: 'or', i18nLabel: t`OR`, value: false },
];

const SmallIconDropdown = styled(Dropdown)`
  &&& {
    font-size: ${svars.fontSizeSmall};
    color: ${(props) =>
      props.value ? svars.accentColor : svars.fontSizeSmall};
    & i {
      margin-left: ${svars.spaceSmall};
    }
  }
`;
const MatchAllConceptsContainer = styled.div`
  cursor: pointer;
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  padding: ${svars.spaceNormal};
  margin-bottom: ${svars.spaceSmall};
  background: ${svars.colorLighterGrey};
`;

const FilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 0;
  align-items: flex-start;
`;

export const OntologyFilterFactory = (
  ontologiesSelector,
  ontologyLabelsSelector,
  isConceptSelected
) => {
  function OntologyFilter({
    ontologyConcepts,
    matchAllConcepts,
    onFilterChange,
    onFilterReset,
    filtersKey,
    hideAllConceptsToggle,
  }) {
    const [filteredOntologyId, setFilteredOntologyId] = useState();

    const ontologyLabels = useSelector(ontologyLabelsSelector);
    const conceptItems = useSelector(ontologiesSelector(filteredOntologyId));

    const onOntologySelect = useCallback(
      (event, { value }) =>
        setFilteredOntologyId(value === filteredOntologyId ? null : value),
      [filteredOntologyId]
    );

    const onToggleMatchAllConcepts = useCallback(
      (value) =>
        value !== matchAllConcepts &&
        onFilterChange(
          {
            matchAllConcepts: value,
          },
          ontologyConcepts.length > 1
        ),
      [matchAllConcepts, ontologyConcepts]
    );

    const ontologyOptions =
      Object.keys(ontologyLabels).length > 1
        ? Object.keys(ontologyLabels).map((ontologyId) => ({
            key: ontologyId,
            value: ontologyId,
            text: ontologyLabels?.[ontologyId]?.label,
            style: {
              color:
                filteredOntologyId === ontologyId
                  ? svars.accentColor
                  : svars.fontColorBase,
            },
            icon: (
              <Icon
                name="check"
                style={{
                  color:
                    filteredOntologyId === ontologyId
                      ? 'inherit'
                      : 'transparent',
                }}
              />
            ),
          }))
        : [];

    return (
      <FilterContainer>
        {Object.keys(ontologyLabels).length > 1 && (
          <SmallIconDropdown
            text={
              filteredOntologyId
                ? ontologyLabels?.[filteredOntologyId]?.label
                : capitalize(t`select-an-ontology`)
            }
            icon="filter"
            clearable
            value={filteredOntologyId}
            onChange={onOntologySelect}
            options={ontologyOptions}
            style={{ marginLeft: svars.spaceNormal, minWidth: '200px' }}
          />
        )}
        {!hideAllConceptsToggle ? (
          <MatchAllConceptsContainer>
            <span style={{ flexGrow: 0, paddingRight: svars.spaceNormalLarge }}>
              <SwitchGroup
                onChange={onToggleMatchAllConcepts}
                value={matchAllConcepts}
                items={CONCEPT_MATCH_SWITCH_ITEMS}
              />
            </span>
            <span
              style={{
                color: svars.fontColorLighter,
                fontStyle: 'italic',
                flexGrow: 1,
                fontSize: svars.fontSizeSmall,
                lineHeight: svars.lineHeightSmall,
              }}
            >
              {matchAllConcepts ? (
                <Trans id="search.all-selected-categories-displayed" />
              ) : (
                <Trans id="search.one-of-selected-categories-displayed" />
              )}
            </span>
          </MatchAllConceptsContainer>
        ) : null}
        <CheckboxListFilter
          valueKey={filtersKey}
          items={sortBy(conceptItems, ['key'])}
          values={ontologyConcepts}
          onFilterChange={onFilterChange}
          onFilterReset={onFilterReset}
          isSelectedItem={isConceptSelected}
        />
      </FilterContainer>
    );
  }
  OntologyFilter.propTypes = {
    filtersKey: PropTypes.string,
    ontologyConcepts: PropTypes.oneOfType([
      // Used when values are concepts db ids
      PropTypes.arrayOf(PropTypes.string),
      // Used when values are objects with ontologyName and conceptId
      // (e.g. in analysis search and filter)
      // TODO: migrate this to use concept db ids
      PropTypes.arrayOf(PropTypes.object),
    ]),
    matchAllConcepts: PropTypes.bool,
    onFilterChange: PropTypes.func.isRequired,
    onFilterReset: PropTypes.func.isRequired,
    hideAllConceptsToggle: PropTypes.bool,
  };
  OntologyFilter.defaultProps = {
    matchAllConcepts: false,
    filtersKey: 'ontologyConcepts',
    ontologyConcepts: [],
    hideAllConceptsToggle: false,
  };
  return OntologyFilter;
};

const AnalysisOntologyFilter = OntologyFilterFactory(
  ontologiesItemsSelector,
  entityOntologyLabelsSelector,
  ({ value }, selectedItems) => selectedItems.includes(value)
);

export default AnalysisOntologyFilter;
