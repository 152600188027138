import React from 'react';
import { useSelector } from 'react-redux';

import { t } from '@lingui/macro';
import PropTypes from 'prop-types';

import {
  viewFacetHierarchyGroupItemsSelector,
  viewFacetSourceItemsSelector,
} from 'selectors/facet';

import CheckboxListFilter from './CheckboxListFilter';
import SearchDateFilter from './DatePeriodFilter';
// import FilterPresetActions from './FilterPresetActions';
import OntologyFilter from './OntologyFilter';
import ProductHierarchyFilter from './ProductHierarchyFilter';
import TextSearchFilter from './TextSearchFilter';

const SENTIMENT_POLARITY_ITEMS = [
  { key: 'positives', i18nLabel: t`positive-sentiment`, value: 'positives' },
  { key: 'negatives', i18nLabel: t`negative-sentiment`, value: 'negatives' },
  // We don't propose meutral filter to avoid showing only neutral chunks
  // { key: 'neutrals', i18nLabel: t`neutral-sentiment`, value: 'neutrals' },
];

export function SourceFilter({
  sources,
  onFilterChange,
  onFilterReset,
  loading,
}) {
  const facetSourceItems = useSelector(viewFacetSourceItemsSelector);
  return (
    <CheckboxListFilter
      valueKey="sources"
      items={facetSourceItems}
      values={sources}
      onFilterChange={onFilterChange}
      onFilterReset={onFilterReset}
      loading={loading}
    />
  );
}

SourceFilter.propTypes = {
  sources: PropTypes.arrayOf(PropTypes.string).isRequired,
  onFilterChange: PropTypes.func.isRequired,
  onFilterReset: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};
SourceFilter.defaultProps = { loading: false };

export function SentimentPolarityFilter({
  polarities,
  onFilterChange,
  onFilterReset,
}) {
  return (
    <CheckboxListFilter
      valueKey="polarities"
      items={SENTIMENT_POLARITY_ITEMS}
      values={polarities}
      onFilterChange={onFilterChange}
      onFilterReset={onFilterReset}
    />
  );
}

SentimentPolarityFilter.propTypes = {
  polarities: PropTypes.arrayOf(PropTypes.string).isRequired,
  onFilterChange: PropTypes.func.isRequired,
  onFilterReset: PropTypes.func.isRequired,
};

export function ProductHierarchyGroupFilter({
  productHierarchyGroups,
  onFilterChange,
  onFilterReset,
}) {
  const facetHierarchyGroupItems = useSelector(
    viewFacetHierarchyGroupItemsSelector
  );
  return (
    <CheckboxListFilter
      valueKey="productHierarchyGroups"
      items={facetHierarchyGroupItems}
      values={productHierarchyGroups}
      onFilterChange={onFilterChange}
      onFilterReset={onFilterReset}
    />
  );
}

ProductHierarchyGroupFilter.propTypes = {
  productHierarchyGroups: PropTypes.arrayOf(PropTypes.string).isRequired,
  onFilterChange: PropTypes.func.isRequired,
  onFilterReset: PropTypes.func.isRequired,
};

export const SEARCH_STATIC_FILTERS = [
  {
    title: t`keywords`,
    key: 'keywords',
    keys: ['textSearchValues'],
    FilterComponent: TextSearchFilter,
  },
  {
    title: t`categories`,
    key: 'categories',
    keys: ['ontologyConcepts', 'matchAllConcepts'],
    FilterComponent: OntologyFilter,
  },
  {
    title: t`products-services`,
    key: 'products-services',
    keys: ['productHierarchies'],
    FilterComponent: ProductHierarchyFilter,
  },
  {
    title: t`period`,
    key: 'period',
    keys: ['datePeriod', 'minDate', 'maxDate'],
    FilterComponent: SearchDateFilter,
  },
  {
    title: t`sources`,
    key: 'sources',
    keys: ['sources'],
    FilterComponent: SourceFilter,
  },
  {
    title: t`sentiment-polarity`,
    key: 'sentiment-polarity',
    keys: ['polarities'],
    FilterComponent: SentimentPolarityFilter,
  },
  {
    title: t`product-services-group`,
    key: 'product-services-group',
    keys: ['productHierarchyGroups'],
    FilterComponent: ProductHierarchyGroupFilter,
  },
];
