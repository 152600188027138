import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { t } from '@lingui/macro';
import PropTypes from 'prop-types';

import { productHierarchiesLoadingSelector } from 'selectors/entities';
import { viewFacetHierarchyItemsSelector } from 'selectors/facet';

import { HierarchicalCheckboxList } from 'components/ui/inputs/checkbox-list';

import * as svars from 'assets/style/variables';

const FILTER_HEADER_STYLES = { padding: `0 ${svars.spaceNormal}` };

function ProductHierarchyFilter({
  productHierarchies,
  onFilterChange,
  onFilterReset,
}) {
  const loading = useSelector(productHierarchiesLoadingSelector);
  const finalItems = useSelector(viewFacetHierarchyItemsSelector);
  const onSelectItems = useCallback(
    (itemsToAdd) =>
      onFilterChange({
        productHierarchies: [
          ...productHierarchies,
          ...itemsToAdd.map(({ value }) => value),
        ],
      }),
    [productHierarchies, onFilterChange]
  );
  const onUnselectItems = useCallback(
    (itemsToRemove) => {
      const finalValues = itemsToRemove.map(({ key }) => key);
      onFilterChange({
        productHierarchies: productHierarchies.filter(
          (itemValue) => !finalValues.includes(itemValue)
        ),
      });
    },
    [productHierarchies, onFilterChange]
  );
  return (
    <HierarchicalCheckboxList
      items={finalItems}
      onSelectItems={onSelectItems}
      onUnselectItems={onUnselectItems}
      onResetItems={onFilterReset(['productHierarchies'], [])}
      selectedItems={productHierarchies}
      isSelectedItem={({ value }) => productHierarchies.includes(value)}
      placeholder={t`search-a-product-service`}
      searchable={finalItems.length > 4}
      headerStyle={FILTER_HEADER_STYLES}
      loading={loading}
    />
  );
}
ProductHierarchyFilter.propTypes = {
  productHierarchies: PropTypes.arrayOf(PropTypes.string),
  onFilterChange: PropTypes.func.isRequired,
  onFilterReset: PropTypes.func.isRequired,
};
ProductHierarchyFilter.defaultProps = {
  productHierarchies: [],
};
export default ProductHierarchyFilter;
