import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { Trans, t } from '@lingui/macro';
import { parseISO } from 'date-fns';
import isBefore from 'date-fns/isBefore';
import PropTypes from 'prop-types';
import { Message } from 'semantic-ui-react';
import styled from 'styled-components';

import { PERIODS_ITEMS } from 'selectors/search';
import { viewFacetSelector } from 'selectors/view';

import ButtonGroup from 'components/ui/button/ButtonGroup';
import { SecondaryTabButton } from 'components/ui/button/TabButton';
import DaySelect from 'components/ui/inputs/DaySelect';

import * as svars from 'assets/style/variables';

const PaddedSecondaryTabButton = styled(SecondaryTabButton)`
  margin-bottom: ${svars.spaceNormal} !important;
`;
const CompactMessage = styled(Message)`
  &&& {
    padding: ${svars.spaceSmall};
    margin: ${svars.spaceNormal} 0;
    & .content {
      padding: ${svars.spaceNormal};
      margin: 0;
    }
  }
`;
const PaddedAccordionItem = styled.div`
  padding: ${svars.spaceNormal} ${svars.spaceMedium} ${svars.spaceMedium}
    ${svars.spaceMedium};
`;

const CustomDateFilterContainer = styled.div`
  display: ${({ disabled }) => (disabled ? 'block' : 'inline-grid')};
  margin-top: ${svars.spaceNormal};
  margin-bottom: ${svars.spaceNormal};
  padding-right: ${svars.spaceMedium};
  height: ${({ disabled }) => (disabled ? 0 : 'auto')};
  overflow: hidden;
  transition: ${svars.transitionBase};
`;

function SearchDateFilter({
  datePeriod,
  minDate,
  maxDate,
  onFilterChange,
  onSetFilterActive,
  // viewFacet could be avoided if we could map required props for filters from chunksearch props
  // viewFacet,
}) {
  const viewFacet = useSelector(viewFacetSelector);
  const onPeriodChange = useCallback(
    (_, { value }) =>
      onFilterChange({ datePeriod: datePeriod === value ? null : value }),
    [datePeriod]
  );
  const onDateChange = useCallback(
    (key) => (date) => {
      onFilterChange({ [key]: date?.toISOString() });
    },
    [onFilterChange]
  );

  const rangeMinDate = viewFacet.min_date
    ? parseISO(viewFacet.min_date)
    : undefined;
  const rangeMaxDate = viewFacet.max_date
    ? parseISO(viewFacet.max_date)
    : undefined;

  // Cast as Date instances
  // NB : it is necessary to cast to and from Date as we cannot
  // store date instances in redux state
  const minDateInstance = useMemo(
    () => (minDate ? parseISO(minDate) : null),
    [minDate]
  );
  const maxDateInstance = useMemo(
    () => (maxDate ? parseISO(maxDate) : null),
    [maxDate]
  );
  const valueError =
    minDateInstance &&
    maxDateInstance &&
    isBefore(maxDateInstance, minDateInstance);
  const customDateIsDisabled = datePeriod !== 'custom';
  return (
    <PaddedAccordionItem>
      <ButtonGroup
        as={PaddedSecondaryTabButton}
        items={PERIODS_ITEMS}
        onChange={onPeriodChange}
        value={datePeriod}
        style={{ flexWrap: 'wrap' }}
      />
      <div style={{ paddingTop: svars.spaceNormal }}>
        <CustomDateFilterContainer disabled={customDateIsDisabled}>
          <Trans id="from" /> ...
          <DaySelect
            layout="column"
            disabled={customDateIsDisabled}
            error={valueError}
            fromDate={rangeMinDate}
            toDate={rangeMaxDate}
            onSelect={onDateChange('minDate')}
            selected={minDateInstance}
            onTogglePopup={onSetFilterActive}
          />
        </CustomDateFilterContainer>

        <CustomDateFilterContainer disabled={customDateIsDisabled}>
          <Trans id="until" /> ...
          <DaySelect
            layout="column"
            disabled={customDateIsDisabled}
            error={valueError}
            fromDate={rangeMinDate}
            toDate={rangeMaxDate}
            onSelect={onDateChange('maxDate')}
            selected={maxDateInstance}
            onTogglePopup={onSetFilterActive}
          />
        </CustomDateFilterContainer>
      </div>
      {valueError && (
        <CompactMessage
          error
          header={t`invalid-period`}
          content={t`facet-create.select-higher-minimum-date`}
        />
      )}
    </PaddedAccordionItem>
  );
}
SearchDateFilter.propTypes = {
  onFilterChange: PropTypes.func.isRequired,
  minDate: PropTypes.string,
  maxDate: PropTypes.string,
  datePeriod: PropTypes.string.isRequired,
  onSetFilterActive: PropTypes.func.isRequired,
};
SearchDateFilter.defaultProps = {
  minDate: null,
  maxDate: null,
};
export default SearchDateFilter;
