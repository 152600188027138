import React, { useCallback, useState } from 'react';
import { connect, shallowEqual, useSelector } from 'react-redux';

import { Trans, t } from '@lingui/macro';
import PropTypes from 'prop-types';
import { Divider, Icon, List } from 'semantic-ui-react';
import styled from 'styled-components';

import { entityLabelFormatter as baseEntityLabelFormatter } from 'selectors/entities';
import {
  PERIODS_ITEMS,
  presetIsEmptySelector,
  searchResultsStatisticsSelector,
} from 'selectors/search';

import EmptyDataPage from 'components/ui/EmptyDataPage';
import Header, { SmallHeader } from 'components/ui/Header';
import Link from 'components/ui/Link';
import Segment from 'components/ui/Segment';
import emptyFiltersUrl from 'components/ui/svg/undraw_circles_y7s2.svg';
import Statistics from 'components/ui/visualization/Statistics';

import commonPropTypes from 'utils/commonPropTypes';
import { formatFacetDates } from 'utils/formatter';

import * as svars from 'assets/style/variables';

function CheckIcon({ check, checkedColor }) {
  const props = check
    ? { name: 'check circle', style: { color: checkedColor } }
    : { name: 'circle', style: { color: svars.colorGrey } };
  return <Icon {...props} />;
}
CheckIcon.propTypes = {
  check: PropTypes.bool.isRequired,
  checkedColor: PropTypes.string.isRequired,
};

function PolaritiesIndicator({ negative, neutral, positive }) {
  return (
    <span>
      <CheckIcon check={negative} checkedColor={svars.absoluteMinColor} />
      <CheckIcon check={neutral} checkedColor={svars.absoluteMidColor} />
      <CheckIcon check={positive} checkedColor={svars.absoluteMaxColor} />
    </span>
  );
}

PolaritiesIndicator.propTypes = {
  negative: PropTypes.bool.isRequired,
  neutral: PropTypes.bool.isRequired,
  positive: PropTypes.bool.isRequired,
};

const N_LIST_ITEM_DISPLAYED = 4;
function ListField({ values }) {
  const [isToggled, setToggled] = useState(false);
  const doToggle = useCallback(() => !setToggled(!isToggled), [isToggled]);
  let toShow = values;
  let showLink = false;
  const nToToggle = values.length - N_LIST_ITEM_DISPLAYED;
  if (nToToggle > 0) {
    showLink = true;
    if (!isToggled) {
      toShow = values.slice(0, N_LIST_ITEM_DISPLAYED);
    }
  }
  return (
    <List>
      {toShow.map((value) => (
        <List.Item key={`li-${value}`}>{value}</List.Item>
      ))}
      {showLink ? (
        <List.Item key="link">
          <Link onClick={doToggle}>
            {isToggled ? <Trans id="show-less" /> : <Trans id="show-more" />}
            {` (${nToToggle})`}
          </Link>
        </List.Item>
      ) : null}
    </List>
  );
}
ListField.propTypes = { values: PropTypes.arrayOf(PropTypes.string) };
ListField.defaultProps = { values: [] };

function FilterField({ label, value }) {
  return (
    <div>
      <SmallHeader
        style={{
          margin: `${svars.spaceSmall} 0`,
          paddingRight: svars.spaceMedium,
        }}
      >
        {label}
      </SmallHeader>
      <div style={{ paddingBottom: svars.spaceMediumLarge }}>
        {Array.isArray(value) ? (
          // eslint-disable-next-line react/no-array-index-key
          // value.map((text, i) => <div key={`txl-${text}-${i}`}>{text}</div>)
          <ListField values={value} />
        ) : (
          value
        )}
      </div>
    </div>
  );
}
FilterField.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  value: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string,
    PropTypes.node,
  ]).isRequired,
};

const ContainerSegment = styled(Segment)`
  &&& {
    width: 400px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0;
    margin-left: ${svars.spaceMedium};
    overflow: hidden;
  }
`;

function FiltersDescription({
  filtersValues,
  entityLabelFormatter,
  onCloseModal,
}) {
  const searchResultsStatistics = useSelector(
    searchResultsStatisticsSelector,
    shallowEqual
  );
  const presetIsEmpty = useSelector(presetIsEmptySelector);
  const selectedPeriod = filtersValues.datePeriod
    ? PERIODS_ITEMS.find(({ value }) => filtersValues.datePeriod === value)
    : null;

  return (
    <ContainerSegment>
      <span
        style={{
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
          height: '100%',
        }}
      >
        <Header>
          <Trans id="Filters" />
        </Header>
        {presetIsEmpty ? (
          <EmptyDataPage
            style={{ padding: `${svars.spaceNormalLarge} ${svars.spaceSmall}` }}
            i18nHeaderText={t`no-filter-set`}
            actionComponent={
              <span>
                <p>
                  <Trans>
                    <Link base="true" onClick={onCloseModal}>
                      Go back to search
                    </Link>{' '}
                    to add filters.
                  </Trans>
                </p>
              </span>
            }
            illustrationUrl={emptyFiltersUrl}
            maxHeight="8rem"
          />
        ) : (
          <div style={{ paddingRight: svars.spaceSmall, overflowY: 'auto' }}>
            {filtersValues.textSearchValues.length ? (
              <FilterField
                label={t`keywords`}
                value={filtersValues.textSearchValues.map((text) =>
                  text.join(', ')
                )}
              />
            ) : null}
            {filtersValues.ontologyConcepts.length ? (
              <FilterField
                label={t`categories`}
                value={filtersValues.ontologyConcepts.map((conceptId) =>
                  entityLabelFormatter('concept', conceptId)
                )}
              />
            ) : null}
            {filtersValues.productHierarchies.length ? (
              <FilterField
                label={t`products-services`}
                value={filtersValues.productHierarchies.map((hierarchyId) =>
                  entityLabelFormatter('productHierarchy', hierarchyId)
                )}
              />
            ) : null}
            {selectedPeriod ? (
              <FilterField
                label={t`period`}
                value={
                  selectedPeriod.key === 'custom' ? (
                    formatFacetDates(
                      filtersValues.minDate,
                      filtersValues.maxDate
                    )
                  ) : (
                    <Trans id={selectedPeriod.i18nLabel} />
                  )
                }
              />
            ) : null}
            {filtersValues.sources.length ? (
              <FilterField
                label={t`sources`}
                value={filtersValues.sources.map((sourceId) =>
                  entityLabelFormatter('source', sourceId)
                )}
              />
            ) : null}
            {filtersValues.polarities.length ? (
              <FilterField
                label={t`sentiment-polarity`}
                value={
                  <PolaritiesIndicator
                    positive={filtersValues.polarities.includes('positives')}
                    negative={filtersValues.polarities.includes('negatives')}
                    neutral={filtersValues.polarities.includes('neutrals')}
                  />
                }
              />
            ) : null}
            {filtersValues.productHierarchyGroups.length ? (
              <FilterField
                label={t`product-services-group`}
                value={filtersValues.productHierarchyGroups.map((hierarchyId) =>
                  entityLabelFormatter('productHierarchyGroup', hierarchyId)
                )}
              />
            ) : null}
            {Object.entries(filtersValues.tags).map(([tagSetId, tagIds]) =>
              tagIds.length ? (
                <FilterField
                  key={`fvti-${tagSetId}`}
                  label={entityLabelFormatter('tagSet', tagSetId)}
                  value={tagIds.map((tagId) =>
                    entityLabelFormatter('tag', tagId)
                  )}
                />
              ) : null
            )}
          </div>
        )}
      </span>
      <span style={{ padding: 0, margin: '0 auto' }}>
        <Divider />
        <Statistics
          disabled={false}
          nChunks={searchResultsStatistics?.n_chunks}
          nChunksLabel={t`Extracts`}
          averageSentiment={
            searchResultsStatistics
              ? searchResultsStatistics.average_sentiment
              : '-'
          }
        />
      </span>
    </ContainerSegment>
  );
}
FiltersDescription.propTypes = {
  filtersValues: commonPropTypes.searchFilterValues.isRequired,
  entityLabelFormatter: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func.isRequired,
};
FiltersDescription.defaultProps = {};

export default connect(
  (state) => ({
    entityLabelFormatter: (entityType, id, meta) =>
      baseEntityLabelFormatter(state.entities, entityType, id, meta),
  }),
  {}
)(FiltersDescription);
