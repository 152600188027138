import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { accentInsensitiveSearchIndices } from 'utils/helpers';

import * as styleVariables from 'assets/style/variables';

const MultilineLimitedTextCell = styled.div`
  position: relative;
  line-height: 1.5rem;
  max-height: calc(1.5rem * 3);
  overflow: hidden;
  padding-right: 1.4rem; /* space for ellipsis */
  margin-right: -4px;

  &::before {
    position: absolute;
    content: '[...]';
    bottom: 0;
    right: 0;
  }

  &::after {
    content: '';
    right: 0;
    position: absolute;
    inset-inline-end: 0;
    width: 1.3rem;
    height: 1.3rem;
  }
`;

const TextCellContainer = styled.span`
  border-radius: 6px;
  margin: 0 0.5px;
  padding: 1px 2px;
`;

function ColoredTextCell({ text, indexFrom, indexTo, matchIndex }) {
  return (
    <TextCellContainer
      key={`text-${indexFrom}-${indexTo}`}
      style={{
        backgroundColor: styleVariables.TEXT_BACKGROUNDS[matchIndex],
      }}
    >
      {text?.slice(indexFrom, indexTo)}
    </TextCellContainer>
  );
}

ColoredTextCell.propTypes = {
  text: PropTypes.string.isRequired,
  indexFrom: PropTypes.number.isRequired,
  indexTo: PropTypes.number.isRequired,
  matchIndex: PropTypes.number.isRequired,
};

const tagTextCell = (text, queries) => {
  const matchedIndices = accentInsensitiveSearchIndices(text, queries, true);
  const components = [];
  let textIndex = 0;
  if (matchedIndices.length && matchedIndices[0][0] !== 0) {
    components.push(text.slice(0, matchedIndices[0][0]));
    [[textIndex]] = matchedIndices;
  }
  matchedIndices.forEach(([indexFrom, indexTo, matchIndex]) => {
    if (indexFrom > textIndex) {
      components.push(text.slice(textIndex, indexFrom));
      components.push(
        <ColoredTextCell
          key={`tw-${indexFrom}-${indexTo}-${matchIndex}`}
          text={text}
          indexFrom={indexFrom}
          indexTo={indexTo}
          matchIndex={matchIndex}
        />
      );
      textIndex = indexTo;
    } else if (indexFrom === textIndex) {
      components.push(
        <ColoredTextCell
          key={`tw-${indexFrom}-${indexTo}-${matchIndex}`}
          text={text}
          indexFrom={indexFrom}
          indexTo={indexTo}
          matchIndex={matchIndex}
        />
      );
      textIndex = indexTo;
    } else if (indexFrom < textIndex && indexTo > textIndex) {
      components.push(
        <ColoredTextCell
          key={`tw-${indexFrom}-${indexTo}-${matchIndex}`}
          text={text}
          indexFrom={textIndex}
          indexTo={indexTo}
          matchIndex={matchIndex}
        />
      );
      textIndex = indexTo;
    }
  });
  if (textIndex < text.length) {
    components.push(text.slice(textIndex, text.length));
  }
  return components;
};

function ColorTaggedTextCell({ value, textSearchValues }) {
  const components = [];
  (typeof value === 'object' ? value : [value]).forEach((text) => {
    components.push(
      ...(typeof text === 'string'
        ? tagTextCell(text, textSearchValues)
        : [text])
    );
  });

  return <MultilineLimitedTextCell>{components}</MultilineLimitedTextCell>;
}

ColorTaggedTextCell.propTypes = {
  value: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.node])
  ),
  textSearchValues: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
};

ColorTaggedTextCell.defaultProps = { value: undefined, textSearchValues: [] };

export default React.memo(ColorTaggedTextCell);
