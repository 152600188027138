import React from 'react';

import { Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import { Grid, Icon, List } from 'semantic-ui-react';
import styled from 'styled-components';

import { displayAnonymizedElements } from 'components/ui/Text';

import commonPropTypes from 'utils/commonPropTypes';
import capitalizedTranslation from 'utils/i18n';

import * as svars from 'assets/style/variables';

const TextCell = styled.span`
  display: flex;
  text-align: justify;
  margin: ${svars.spaceXSmall} ${svars.spaceSmall};
  width: fit-content;
  background: ${(props) =>
    props.highlight ? svars.textHighlightGradient : 'transparent'};
`;

const positiveNegativePointFactory = (isPositive) => {
  function PositiveNegativePoint({ item, style }) {
    return (
      <List.Item style={{ display: 'flex', ...style }}>
        <Icon
          style={{
            color: isPositive ? svars.absoluteMaxColor : svars.absoluteMinColor,
            paddingTop: 0,
            fontSize: svars.fontSizeMedium,
            verticalAlign: 'middle',
          }}
          name={`${isPositive ? 'plus' : 'minus'} circle`}
          size={null}
        />
        <span style={{ verticalAlign: 'middle', marginLeft: svars.spaceSmall }}>
          <TextCell>{displayAnonymizedElements(item)}</TextCell>
        </span>
      </List.Item>
    );
  }
  PositiveNegativePoint.propTypes = {
    item: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    style: PropTypes.object,
  };
  PositiveNegativePoint.defaultProps = { item: null, style: {} };
  return PositiveNegativePoint;
};

export const PositivePoint = positiveNegativePointFactory(true);
export const NegativePoint = positiveNegativePointFactory(false);

export function ReviewPositiveNegativePointsList({ items, negative }) {
  if (!items?.length) return null;
  const PointComponent = negative ? NegativePoint : PositivePoint;
  return (
    <List>
      {items.map((item) => (
        <PointComponent
          style={{ paddingBottom: svars.spaceNormalLarge }}
          key={item}
          item={item}
        />
      ))}
    </List>
  );
}

ReviewPositiveNegativePointsList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.string),
  // Whether the list of point is of negative sentiment (default is : positive sentiment)
  negative: PropTypes.bool,
};

ReviewPositiveNegativePointsList.defaultProps = {
  items: [],
  negative: false,
};

export function ReviewOriginalTexts({
  review,
  highlightTextField,
  highlightChunkIndex,
}) {
  return (
    review && (
      <Grid padded style={{ textAlign: 'justify' }}>
        <Grid.Row columns={1}>
          <Grid.Column>
            {review.texts &&
              review.texts.map((text, i) => (
                <TextCell
                  highlight={
                    highlightTextField === 'texts' && i === highlightChunkIndex
                  }
                  key={`rtt-${text.substring(0, 20)}`}
                >
                  {displayAnonymizedElements(text)}
                </TextCell>
              ))}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={16}>
            {review.positive_points && (
              <ReviewPositiveNegativePointsList
                items={review.positive_points}
                highlightIndex={
                  highlightTextField === 'positive_points'
                    ? highlightChunkIndex
                    : null
                }
              />
            )}
          </Grid.Column>
          <Grid.Column width={16}>
            {review.negative_points && (
              <ReviewPositiveNegativePointsList
                items={review.negative_points}
                highlightIndex={
                  highlightTextField === 'negative_points'
                    ? highlightChunkIndex
                    : null
                }
                negative
              />
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    )
  );
}

ReviewOriginalTexts.propTypes = {
  review: commonPropTypes.review.isRequired,
  highlightTextField: PropTypes.string,
  highlightChunkIndex: PropTypes.number,
};
ReviewOriginalTexts.defaultProps = {
  highlightChunkIndex: null,
  highlightTextField: null,
};

const ReviewChunksLegendContainer = styled.div`
  font-size: ${svars.fontSizeSmaller};
  display: flex;
  flex-direction: row;
  align-items: center;
  & > i {
    margin-left: ${svars.spaceMedium};
  }
`;

export function ReviewChunksLegend() {
  return (
    <ReviewChunksLegendContainer>
      <Icon name="fork" />
      <Trans render={capitalizedTranslation} id="concepts" />
      <Icon name="tags" />
      <Trans render={capitalizedTranslation} id="tags" />
    </ReviewChunksLegendContainer>
  );
}
