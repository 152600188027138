import React, { useCallback, useMemo, useState } from 'react';

import { Trans, t } from '@lingui/macro';
import PropTypes from 'prop-types';
import { Grid, Icon } from 'semantic-ui-react';
import styled from 'styled-components';

import { NoMarginGrid } from 'components/ui/Grid';
import HelpTooltip from 'components/ui/HelpTooltip';
import ExportAsXlsButton from 'components/ui/button/export-as/ExportAsXls';
import { AnalyticsAwareCheckbox } from 'components/ui/inputs/Checkbox';
import { TextInput } from 'components/ui/inputs/TextInput';
import useTableFilter from 'components/ui/search/useTableFilter';
import { useReactTableColumns } from 'components/ui/table/ReactTableColumns';
import StyledReactTable from 'components/ui/table/ReactTableUi';

import { logEvent } from 'utils/analytics';
import { roundWithPrecision } from 'utils/formatter';
import { capitalize } from 'utils/helpers';

import * as svars from 'assets/style/variables';

const COLUMNS_MAP = {
  concept: { id: 'name' },
  n_chunks: { id: 'n_chunks' },
  share_of_extracts: { id: 'share_of_extracts' },
  average_sentiment: { id: 'average_sentiment' },
};

const EXPORT_COLUMNS = [
  {
    key: 'label',
    label: t`category`,
    width: 50,
  },
  { key: 'n_chunks', label: t`volume` },
  {
    key: 'share_of_extracts',
    label: t`share-of-extracts`,
    formatter: roundWithPrecision,
  },
  {
    key: 'average_sentiment',
    label: t`sentiment`,
    formatter: roundWithPrecision,
  },
];

const DEFAULT_SORTED = [{ id: 'n_chunks', desc: true }];
const DefinitionContainer = styled.i`
  display: flex;
  padding: ${svars.spaceMedium} ${svars.spaceSmall} ${svars.spaceSmall}
    ${svars.spaceSmall};
  background-color: ${svars.colorGreyMedium};
  font-size: ${svars.fontSizeSmall};
  line-height: 1rem;
`;

function ExplorationOntologyTable({
  data,
  onRowClick,
  selectedRow,
  legend,
  height,
}) {
  const [leavesOnly, setLeavesOnly] = useState(true);
  const toggleLeavesOnly = useCallback(
    () => setLeavesOnly(!leavesOnly),
    [leavesOnly]
  );
  const filterLeavesOnly = useCallback(
    (row) => {
      if (!leavesOnly) {
        return true;
      }
      const parents = [...new Set(data.map((item) => item.parent_id))];
      return !leavesOnly || !parents.includes(row.id);
    },
    [leavesOnly]
  );
  const [filteredData, conceptFilterValue, filterConcepts] = useTableFilter(
    data,
    'label',
    [leavesOnly],
    filterLeavesOnly
  );
  if (!filteredData) return null;
  const maxNChunks = Math.max(...filteredData.map((row) => row.n_chunks));
  const columns = useMemo(
    () => useReactTableColumns(COLUMNS_MAP, maxNChunks),
    [COLUMNS_MAP, maxNChunks]
  );
  return (
    <>
      <NoMarginGrid style={{ paddingBottom: svars.spaceNormalLarge }}>
        <Grid.Row
          style={{
            padding: 0,
          }}
        >
          <Grid.Column width={8} verticalAlign="bottom">
            <TextInput
              style={{ width: '100%', height: '100%' }}
              icon="search"
              placeholder={capitalize(t`search-a-category`)}
              value={conceptFilterValue}
              onChange={filterConcepts}
            />
          </Grid.Column>
          <Grid.Column
            width={7}
            floated="right"
            textAlign="right"
            verticalAlign="bottom"
          >
            <HelpTooltip
              help={<Trans id="help-message.parent-category-description" />}
              size="tiny"
              position="top center"
              trigger={
                <AnalyticsAwareCheckbox
                  gaCategory="Customer"
                  gaAction="Analyze - toggle parents nodes"
                  gaLabel={`${leavesOnly}`}
                  clicked={leavesOnly ? 1 : 0}
                  onClick={toggleLeavesOnly}
                  style={{
                    borderRadius: 0,
                    textAlign: 'initial',
                    fontWeight: svars.fontWeightBase,
                    fontSize: svars.fontSizeSmaller,
                  }}
                  label={capitalize(t`display-also-parent-categories`)}
                  checked={!leavesOnly}
                />
              }
            />
          </Grid.Column>
          <Grid.Column
            width={1}
            floated="right"
            textAlign="right"
            verticalAlign="bottom"
          >
            <ExportAsXlsButton
              exportName={capitalize(t`categories`)}
              data={filteredData}
              columns={EXPORT_COLUMNS}
            />
          </Grid.Column>
        </Grid.Row>
      </NoMarginGrid>
      <StyledReactTable
        style={{ height }}
        defaultSorted={DEFAULT_SORTED}
        key={`treemap-table-leafonly-${leavesOnly}`}
        data={filteredData}
        columns={columns}
        noDataText={t`not-enough-data`}
        selectedRow={selectedRow}
        rowKey="id"
        onRowClick={(row) => {
          onRowClick(row.id);
          logEvent({
            category: 'Customer - Analyze',
            action: 'Select concept - Table',
            label: row.label,
          });
        }}
      />
      {legend.length ? (
        <DefinitionContainer>
          <div>
            {legend.map((item) => (
              <div
                key={`help-${item.slice(0, 20)}`}
                style={{ paddingBottom: '4px' }}
              >
                <Icon name="attention" />{' '}
                <span key={`definition-${item}`}>
                  <Trans id={item} />
                </span>
              </div>
            ))}
          </div>
        </DefinitionContainer>
      ) : null}
    </>
  );
}

const rowPropType = PropTypes.shape({
  id: PropTypes.string,
  n_chunks: PropTypes.number,
  label: PropTypes.string,
  average_sentiment: PropTypes.number,
  parent_id: PropTypes.string,
});
ExplorationOntologyTable.propTypes = {
  data: PropTypes.arrayOf(rowPropType).isRequired,
  onRowClick: PropTypes.func,
  selectedRow: rowPropType,
  legend: PropTypes.arrayOf(PropTypes.string),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
ExplorationOntologyTable.defaultProps = {
  onRowClick: () => null,
  selectedRow: null,
  legend: [],
  height: '45vh',
};

export default ExplorationOntologyTable;
